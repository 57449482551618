import React, { FC, useEffect } from 'react';
import { useSelector } from 'react-redux';
import {
    activeRequestSelector,
    requestsDataSelector,
} from '../../../../../redux/selector/requestSelector';
import { userDataSelector } from '../../../../../redux/selector/userSelector';
import useFetchDetailedRequest from '../../hook/useFetchDetailedRequest';

import { ChatRequest } from '../../../../../entity/type/ChatRequest';
import { AuthenticatedUser, User } from '../../../../../entity/type/User';
import { PaginatedResponse } from '../../../../../api/type/PaginatedResponse';

import { Col, Row } from 'antd';
import Chat from '../../../../common/chat/Chat';
import LeftMenu from '../left_menu/LeftMenu';
import AvailableRequests from './available_requests/AvailableRequests';
import OfferMaker from './offer_master/offer_maker/OfferMaker';

import { RequestService } from '../../../../../util/RequestService';

import './operator_content.css';


const OperatorContent: FC = () => {
    const requests: PaginatedResponse<ChatRequest> = useSelector(requestsDataSelector);
    const activeRequest: PaginatedResponse<ChatRequest> = useSelector(activeRequestSelector);
    const user: AuthenticatedUser = useSelector(userDataSelector);
    const userData: User = user?.user;

    useEffect(() => {
        RequestService.getRequests({ operatorId: userData.id, clientId: userData.id });
        RequestService.getNotStartedRequests();
    }, []);

    useFetchDetailedRequest();

    return (
        <>
            <Row>
                <Col xs={24} sm={5} md={5} lg={5} xl={5}>
                    <LeftMenu requestsData={requests} userData={userData} />
                </Col>
                <Col xs={24} sm={10} md={10} lg={10} xl={10}>
                    <div className='iauto-cabinet-request-container'>
                        {activeRequest && <Chat />}
                    </div>
                </Col>
                <Col xs={24} sm={9} md={9} lg={9} xl={9}>
                    <AvailableRequests />
                </Col>
            </Row>
            <OfferMaker />
        </>
    );
};

export default OperatorContent;
